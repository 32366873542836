* {
    --theme-color: #8C52FF;
    --progress-bar: #d4c7ee;

}

.btn-outline-primary {
    border-color: var(--theme-color) !important;
    color: var(--theme-color) !important;
}

.btn-outline-primary:hover {
    color: white !important;
}

.container #pro {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

}

.project-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    padding: 0.8rem;
    padding-left: 1rem;
    padding-right: .5rem;
    box-shadow: 1px 1px 1px 1px rgb(162, 155, 170);
    border-radius: 5px;
    width: 80%;
    min-height: 50vh;

}

.project-container .p-picture div img {

    display: flex;

    align-items: center;

}

.p-picture {
    height: 400px;
    width: 40%;

}

.p-informations {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 2rem;
    width: 60%;



}

.p-description-short {
    display: block;
    /* or inline-block */
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 3.6em;
    line-height: 1.8em;
}



.p-informations .p-header {
    display: flex;

    justify-content: space-between;
    flex-direction: row;
}

.p-gand {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.p-gand li {
    list-style: none;
    margin-left: 1rem;
    font-family: sans-serif;
}

.p-gand li span:nth-child(1) {
    font-weight: 800;

    font-style: normal;
    font-variant: numeric;
    font-size: 1.125rem;
    font-family: inherit;
    margin-bottom: 0.5rem;
    line-height: 2.6;
    color: #0a3734;
}

.p-button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.p-header div span:nth-child(1) {
    font-size: 30px;
    font-weight: 700;
    margin-right: 1rem;
}

.type-investor {
    color: var(--theme-color);
    font-weight: 600;
}


@media only screen and (max-width : 900px) {
    .container #pro {
        flex-direction: column;
    }

    .project-container {
        flex-direction: column;
        padding-left: 0px;
        width: 100%;
    }

    .p-informations {
        padding-left: 0px;
        width: 100%;
    }

    .p-picture {
        width: 100%;
        height: 300px;
    }

    .p-gand {
        width: 100%;
        flex-wrap: wrap;

    }

    .p-gand li {
        margin-left: 0%;
        font-size: 8px;

    }

    ul.p-gand {
        margin-left: 0%;
        padding-left: 1px;
    }
}




/* detaill project */
.p-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 3rem;
    padding-bottom: 1rem;
    padding-left: 5rem;
    padding-right: 2rem;
    width: 94%;


}

.p-details-header {
    display: flex;
    flex-direction: row;
}

.p-sommary-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 2.5rem;
    width: 60%;
}

.p-sommary-details div h2:nth-child(1) {
    font-weight: bolder;
}

.bref-summary {
    font-family: sans-serif;
    text-align: justify;
    word-wrap: break-word;
    text-overflow: ellipsis;
    width: 100%;
    padding-bottom: 1rem;
}

.p-details-image {
    width: 40%;
    height: 300px;


}

.gand-info {
    border: 1px rebeccapurple solid;
    width: 100%;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgb(46, 45, 45);
    padding-right: .5rem;

}

.gand-info ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: .5rem;

}

.gand-info ul li {
    margin-left: 1rem;
    margin-right: 1rem;
    list-style: none;

}

.gand-info ul li span:nth-child(1) {
    font-weight: 800;

    font-style: normal;
    font-variant: numeric;
    font-size: 1.125rem;
    font-family: inherit;
    margin-bottom: 0.5rem;
    line-height: 2.6;
    color: #0a3734;


}

.p-menu-bar {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    margin-top: 2rem;

}

.p-menu-bar ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}

.p-menu-bar ul li {
    list-style: none;


}

.p-menu-bar ul li:hover {
    /* text-decoration: overline; */
    transition: 0.2s;
    cursor: pointer;
    border: solid #ff5a5f;
    border-width: 3px 0 0;

}

.description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.text {
    text-align: justify;
    max-width: 70%;
    font-family: sans-serif;
}

.text h1:nth-child(1) {
    font-family: Fallback, sans-serif;

}

.compagnie-detail {
    margin-left: 2rem;



}

.p-menu-bar ul li a {
    text-decoration: none;
    color: black;
    font-size: 20px;
}

.link-active {
    border: solid #ff5a5f;
    border-width: 3px 0 0;

}

.nav-style {
    font-family: sans-serif;
}

.progess-bar {
    width: 100%;
}

.pdg {
    font-size: 1rem;
    color: "rgb(167, 121, 194)";
    font-weight: "bold"
}